<template>
  <div class="page-saving">
    <v-card class="savings primary-card mb-4" color="primary" dark>
      <v-card-title>
        <v-icon class="white--text mr-3">far fa-lightbulb-dollar</v-icon>
        <span class="title">Besparingen</span>
      </v-card-title>
      <v-card-text>
        <h1 v-if="notFound">
          Er zijn geen uitgevoerde acties
        </h1>
        <saving @notFound="notFound  = true" v-else></saving>
      </v-card-text>
    </v-card>
    <v-row class="ma-0">
      <v-col class="mb-4 pa-0" md="4" sm="6" cols="12" :class="{'pr-2': $vuetify.breakpoint.smAndUp}">
        <v-card class="white-card fill-height">
          <v-card-title>
            <v-icon class="white--text mr-3">far fa-smog</v-icon>
            <span class="title">Totale CO2 reductie</span>
          </v-card-title>
          <v-card-text v-if="'carbon_dioxide' in savings" class="black--text">
            <v-col class="body-2 mb-1 pa-0 mt-5 black--text">Op dit moment heb je {{
                savings.carbon_dioxide.saved |
                    parseToNumber
              }} ton CO2 bespaard
            </v-col>
            <p>Dit staat gelijk aan de hoeveelheid die:</p>
            <v-row class="ma-0">
              <v-col class="body-1 mb-1 pa-0" cols="2">
                <v-icon color="primary">fal fa-trees</v-icon>
              </v-col>
              <v-col cols="10" class="pa-0">{{ savings.carbon_dioxide.equals.trees | parseToNumber }} bomen jaarlijks
                opnemen
              </v-col>
            </v-row>
            <v-row class="ma-0">
              <v-col class="body-1 mb-1 pa-0" cols="2">
                <v-icon color="primary">fal fa-car</v-icon>
              </v-col>
              <v-col cols="10" class="pa-0">{{ savings.carbon_dioxide.equals.cars | parseToNumber }}
                personenauto's per jaar uitstoten
              </v-col>
            </v-row>
            <v-row class="ma-0">
              <v-col class="body-1 mb-1 pa-0" cols="2">
                <v-icon color="primary">fal fa-home</v-icon>
              </v-col>
              <v-col cols="10" class="pa-0">{{ savings.carbon_dioxide.equals.house_holds | parseToNumber }}
                huishoudens per jaar uitstoten
                door elektriciteit en gasverbruik
              </v-col>
            </v-row>
          </v-card-text>
          <v-card-text v-else>
            <v-col class="body-2 mb-1">Er is nog geen reductie van CO2 uitstoot. Zet acties op uitgevoerd
              om uw CO2-reductie te zien.
            </v-col>
          </v-card-text>
        </v-card>
      </v-col>
      <v-col class="mb-4 pa-0" cols="12"
             sm="6"
             md="4"
             :class="{'pl-3': $vuetify.breakpoint.smAndUp, 'pr-3': $vuetify.breakpoint.mdAndUp}">
        <v-card class="white-card fill-height">
          <v-card-title>
            <v-icon class="white--text mr-3">far fa-chart-line-down</v-icon>
            <span class="title">Bespaard verbruik</span>
          </v-card-title>
          <v-card-text v-if=" 'usage' in savings " class="black--text">
            <v-row class="ma-0 mt-5">
              <v-col class="body-1 mb-1 pa-0" cols="2">
                <v-icon color="primary">fal fa-bolt</v-icon>
              </v-col>
              <v-col cols="10" class="pa-0">{{ savings.usage.electricity.saved | parseToNumber }} kWh van
                {{ savings.usage.electricity.used | parseToNumber }}
                kWh bespaard
              </v-col>
            </v-row>
            <v-row class="ma-0">
              <v-col class="body-1 mb-1 pa-0" cols="2">
                <v-icon color="primary">fal fa-bolt</v-icon>
              </v-col>
              <v-col cols="10" class="pa-0">{{ savings.usage.electricity.percentage }}%
                kWh bespaard
              </v-col>
            </v-row>
            <v-row class="ma-0">
              <v-col class="body-1 mb-1 pa-0" cols="2">
                <v-icon color="accent">fal fa-burn</v-icon>
              </v-col>
              <v-col cols="10" class="pa-0">{{ savings.usage.gas.saved | parseToNumber }} m³ van
                {{ savings.usage.gas.used | parseToNumber }}
                m³ bespaard
              </v-col>
            </v-row>
            <v-row class="ma-0">
              <v-col class="body-1 mb-1 pa-0" cols="2">
                <v-icon color="accent">fal fa-burn</v-icon>
              </v-col>
              <v-col cols="10" class="pa-0">{{ savings.usage.gas.percentage }}%
                m³ bespaard
              </v-col>
            </v-row>
          </v-card-text>
          <v-card-text v-else>
            <v-col class="body-2 mb-1">Er is nog geen besparing op het verbruik
            </v-col>
          </v-card-text>
        </v-card>
      </v-col>
      <v-col class="mb-4 pa-0" cols="12" md="4" :class="{'pl-2': $vuetify.breakpoint.mdAndUp}">
        <v-card class="white-card fill-height">
          <v-card-title>
            <v-icon class="white--text mr-3">far fa-lightbulb-dollar</v-icon>
            <span class="title">Verwachte kostenbesparing</span>
          </v-card-title>
          <v-card-text class="text-center pt-3 black--text">
                        <span class="title" v-if=" 'expected_costs_saved' in savings ">
                            {{ savings.expected_costs_saved | currency }} per jaar
                        </span>
            <span class="title" v-else>
                            € 0
                        </span>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import Saving from '../components/charts/Saving.vue';

export default {
  components: { Saving },
  watch: {},
  props: [],
  name: 'page-saving',
  data() {
    return {
      savings: {},
      notFound: false,
    };
  },
  created() {
    this.$http.get('me/organisation/current/saving/statistic').then((response) => {
      this.savings = response.data.data;
    });
  },
  methods: {},

};
</script>

<style lang="scss" scoped>

.page-saving {
  width: 100%;
}

.savings canvas {
  height: 200px;
}
</style>
