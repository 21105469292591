<script>
import Chart from 'chart.js';
import { isMobileOnly } from 'mobile-device-detect';
import { Line } from 'vue-chartjs';

let moment = require('moment');

Chart.Tooltip.positioners.custom = function (chartElements, coordinates) {
  return coordinates;
};

export default {
  name: 'Saving',
  extends: Line,
  created() {
    this.getChartData().then((data) => {
      this.renderChart(data, {
        legend: {
          display: false,
        },
        title: {
          text: 'Er is tot nu toe ' + this.$options.filters.currency(data.totalSaved) + ' bespaard',
          display: true,
          position: 'top',
          fontColor: '#ffffff',
          fontSize: 14,
        },
        responsive: true,
        lineTension: 1,
        scales: {
          yAxes: [{
            display: false,
          }],
          xAxes: [{
            display: false,
            type: 'time',
            time: {
              unit: 'month',
            },
            ticks: {
              display: true,
              fontColor: 'white',
              autoSkip: true,
              maxTicksLimit: 50,
            },
          }],
        },
        tooltips: {
          enabled: !isMobileOnly,
          backgroundColor: 'rgb(234, 160, 2)',
          displayColors: false,
          titleFontColor: 'white',
          bodyFontColor: 'white',
          callbacks: {
            title: (tooltipItem, data) => {
              return `Besparing tot en met ${this.$moment(data.datasets[0].data[tooltipItem[0].index].t)
                  .format('L')}: € ` +
                  Number(tooltipItem[0].value).toLocaleString('nl-NL', {
                    maximumFractionDigits: 0,
                  });
            },
            label: (tooltipItem, data) => {
              let names = data.datasets[0].data[tooltipItem.index].name;

              if (names === 'start' || names === 'stop' || names === '') {
                return '';
              }

              if (!names.length) {
                return '';
              }

              names = names.split('\n').map((name) => {
                if (name === 'start' || name === 'stop' || name === '') {
                  return '';
                }
                return '  - ' + name;
              });

              return ['Uitgevoerde actie' + (names.length > 1 ? 's' : '') + ' op deze dag:'].concat(names);

            },
          },
          position: 'nearest',
          mode: 'single',
          intersect: false,
        },
        hover: {
          mode: 'nearest',
          intersect: false,
        },
        maintainAspectRatio: false,
      });
    });

  },
  methods: {
    getChartData() {
      return new Promise((resolve) => {
        this.$http.get('me/organisation/current/saving/step').then((response) => {
          //I need a start and end date for counting the total days
          let steps_end_date = Object.keys(response.data.data.steps);
          steps_end_date = steps_end_date[(steps_end_date.length)];

          let steps_start_date = response.data.data.start_date;
          let steps_total_days = this.countDaysBetweenDates(steps_start_date, steps_end_date);

          let steps_current_step = 0;
          let steps_current_point = Object.values(response.data.data.steps)[0].point;

          let point_radius = [];
          let hit_radius = [];

          let data = {
            labels: Object.values(response.data.data.steps).map(data => {
              return new Date(data.executed_at);
            }),
            datasets: [{
              backgroundColor: ['rgba(255,255,255,0.3)'],
              borderColor: ['white'],
              borderWidth: 1,
              pointBackgroundColor: '#ffffff',
              pointHoverBackgroundColor: '#eaa002',
              pointHoverBorderColor: '#eaa002',
              pointRadius: point_radius,
              hitRadius: hit_radius,
              lineTension: 0,
              pointHoverRadius: 5,
              data: Array.apply(null, Array(steps_total_days)).map((r, index) => {
                let current_date = this.addDays(new Date(steps_start_date), index);
                let current_date_compiled = this.createDate(current_date);

                steps_current_point += steps_current_step;
                if (current_date_compiled in response.data.data.steps) {
                  steps_current_step = response.data.data.steps[current_date_compiled].step;
                  let current_point_radius = steps_current_step === 0 ? 0 : 5;
                  const name = this.actionsToName(response.data.data.steps[current_date_compiled].meter_actions);
                  if (name === 'stop') {
                    current_point_radius = 0;
                  }
                  point_radius.push(current_point_radius);
                  hit_radius.push(25);

                  return {
                    y: steps_current_point,
                    t: current_date_compiled,
                    name: name,
                    meter_actions: response.data.data.steps[current_date_compiled].meter_actions,
                    custom: {
                      radius: 0,
                    },
                  };
                } else {
                  point_radius.push(0);
                  hit_radius.push(1);

                  return {
                    y: steps_current_point,
                    t: new Date(current_date_compiled),
                    name: this.actionsToName([{
                      meter_action_id: 0,
                      name: '',
                    }]),
                    meter_actions: [{
                      meter_action_id: 0,
                      name: '',
                    }],
                    custom: {
                      radius: 0,
                    },
                  };
                }
              }),
            }],
            totalSaved: Object.values(response.data.data.steps)[Object.values(response.data.data.steps).length - 1].point,
          };
          resolve(data);

        }).catch(() => {
          this.$emit('notFound');
        });
      });
    },
    actionsToName(actions) {
      let name = '';
      let more = 0;
      let newLine = '\r\n';

      for (let x = 0; x < actions.length; x++) {
        if (x > 2) {
          more++;
          continue;
        }

        name += actions[x].name + newLine;
      }

      return more > 0 ? `${name}${more} overige actie(s)...` : name.slice(0, name.length - newLine.length);
    },
    countDaysBetweenDates(firstDate, secondDate) {
      firstDate = moment(firstDate);
      secondDate = moment(secondDate);

      return secondDate.diff(firstDate, 'days');
    },
    createDate(date) {
      let month = date.getUTCMonth() + 1;
      let day = date.getUTCDate();
      let year = date.getUTCFullYear();

      month = month < 10 ? '0' + month : month;
      day = day < 10 ? '0' + day : day;

      return year + '-' + month + '-' + day;
    },
    addDays(date, days) {
      date.setDate(date.getDate() + days);

      return date;
    },
  },
};
</script>
